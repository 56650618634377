import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import Layout, {
  Player,
  ViewButtonNext,
  ViewButtonPrevious,
  ShareButtons,
  Breadcrumbs,
} from '../components/layout';
import SEO from '../components/seo';
import Sound from '../audio/fr/voixoff_FR_40.mp3';
import Background from '../images/part-4.jpg';

const ViewArticle = styled.article`
  display: flex;
  flex: 1 0 auto;
  flex-flow: column nowrap;
  justify-content: center;
  overflow-y: auto;
  max-width: 100%;
  background-image: url(${Background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`;

const Part4 = () => (
  <Layout location={typeof window !== 'undefined' ? location : null}>
    <SEO pathname="/fr/part-4" />

    <ViewArticle></ViewArticle>
    <Player sound={Sound} />

    <Link to="/fr/home">
      <ViewButtonPrevious color="warning">
        <i className="fa fa-chevron-left fa" />
      </ViewButtonPrevious>
    </Link>
    <Link to="/fr/part-4-quizz-form">
      <ViewButtonNext color="warning">
        <i className="fa fa-chevron-right" />
      </ViewButtonNext>
    </Link>
    <ShareButtons />
  </Layout>
);

export default Part4;
